

import BackButton from './BackButton'
import GoalServeAPI from '@/utils/goalserve/goalserveAPI'
const dateformat = require('dateformat')
// import {scroller} from 'vue-scrollto/src/scrollTo'
export default {
  name: 'leaguedetail',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean

  },
  data() {
    return {
      lang: this.$store.state.lang,
      loadingN: false,
      loadingS: false,
      loadingF: false,
      snackbar: false,
      snackbarText: '',
      tagLeagueIndex: null,
      toggleCategory: -1,
      toggleView: 'left',
      toggleRecentAll: 'recent',
      news: [],
      limit: 4,
      tabModel2: 0,
      tabArr2: [
        { name: 'Groups' },
        { name: 'Standings' },
        { name: 'Fixtures' }
      ],
      leagueStandings: [],
      leagueStandingsGroup: [],
      selectedGroup: '',
      oldStLeagueId: -1,
      oldFixLeagueId: -1,
      standingsLeagueId: 0,
      leagueFixtures: [],
      showRecentFixtures: true,
      imageProd: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/news/',
      imageProdFF: 'https://olahbolamedia.sgp1.digitaloceanspaces.com/fanfeatures/'
    }
  },
  components: {
    BackButton,
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 770) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    },
    toggleView() {
      if (this.toggleView === 'left') {
        if (this.news.length === 0) {
          this.loadNewsLeague()
        }
      } else if (this.toggleView === 'center') {
        this.checkLeagueTab()
      } else if (this.toggleView === 'right') {
        this.checkLeagueTab()
      }
    }
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language || 'en'
    },
    getPlatform() {
      return this.$store.state.device.platform
    },
    tagLeagues() {
      return this.$store.state.tagLeagues
    },
    tagCategory() {
      return this.$store.state.tagCategory
    },
    getFlags() {
      return this.$store.state.flags
    },
    filteredNews() {
      return this.news.filter(item => {
        if (this.toggleCategory === -1) {
          return true
        } else {
          return item.tag_category === this.toggleCategory
        }
        return true
      })
    },
    getMyMatches() {
      return this.$store.state.user.mymatches
    },
    filteredFixtures() {
      return this.leagueFixtures.filter(item => {
        if (this.showRecentFixtures) {
          return item['@recent']
        }
        return true
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.resultInterval)
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    this.$store.commit('ShowNav')
    this.$store.commit('ShowNavHome')
    this.tagLeagueIndex = this.tagLeagues.findIndex(item => item.slug === this.$route.params.slug)
    this.checkLeagueTab()
  },
  methods: {
    loadNewsLeague() {
      this.loadingN = true
      var params = {
        limit: this.limit,
        tag_league: this.tagLeagueIndex,
        status: 2,
      }
      Promise.resolve(this.$axios.$get('/api/news', { params }))
        .then(docs => {
          this.news = []
          if (docs.length > 0) {
            docs.forEach(doc => {
              var obj = doc
              obj.id = doc._id
              obj.readmore = false
              this.news.push(obj)
            })
            if (this.count < 4) {
              this.limit += 4
              this.loadNewsLeague()
              return
            }
          }
          this.loadingN = false
        })
        .catch(err => {
          this.loadingN = false
          console.log(`Error getting documents: ${err}`)
        })
    },
    loadMoreLeague() {
      this.limit += 4
      this.loadNewsLeague()
    },
    checkLeagueTab() {
      if (this.toggleView === 'center' && this.oldStLeagueId !== this.tagLeagueIndex) {
        this.getLeagueStandings()
        this.oldStLeagueId = this.tagLeagueIndex
      } else if (this.toggleView === 'right' && this.oldFixLeagueId !== this.tagLeagueIndex) {
        this.getLeagueFixtures()
        this.oldFixLeagueId = this.tagLeagueIndex
      } else {
        this.loadNewsLeague()
      }
    },
    openNewsArticle(item) {
      var bucket = item.category === 'fanfeature' ? 'fanfeatures' : 'news'
      var bucket = item.category === 'fanfeature' ? 'fanfeatures' : 'news'
      this.readMoreClicked(item, bucket)
      if (item.category === 'banner') {
        window.open(item.news_link, '_blank')
        return
      } else {
        this.$router.push('/news/' + item.slug)
      }
    },
    readMoreClicked(item, bucket) {
      let dispatchObj = {
        views: item.views += 1
      }
      const config = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      }
      Promise.resolve(this.$axios.$put(`/api/${bucket}/${item.id}`, dispatchObj, config))
        .then(res => {
          console.log('Item views in news bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch(err => {
          console.log(`Encountered error: ${err}`)
        })
    },
    getLeagueStandings() {
      this.leagueStandings = []
      this.leagueStandingsGroup = []
      this.selectedGroup = '',
      this.loadingS = true
      this.tabModel2 = 1
      this.standingsLeagueId = this.tagLeagues[this.tagLeagueIndex].league_id
      Promise.resolve(GoalServeAPI.getLeagueStandings(this.standingsLeagueId))
        .then(result => {
          if (result.toString() === 'Error: Request failed with status code 500') {
            this.loadingS = false
            return
          }
          console.log(result)
          if (Array.isArray(result.data.standings.tournament)) {
            // this.tabArr2.unshift({name: 'Groups'})
            this.tabModel2 = 0
            this.leagueStandingsGroup = result.data.standings.tournament
            // this.leagueStandings = result.data.standings.tournament[0].team
          } else {
            this.leagueStandings = result.data.standings.tournament.team
          }
          this.loadingS = false
        }).catch(error => {
          this.loadingS = false
          console.log(error)
        })
    },
    leagueStandingGroupSelected(item) {
      let arrCheck = Object.keys(item)
      if (arrCheck.filter(item => item === '@league').length > 0) {
        this.selectedGroup = item['@league']
      } else {
        this.selectedGroup = item['@name']
      }
      this.leagueStandings = item.team
      this.tabModel2 = 1
    },
    getLeagueFixtures() {
      this.loadingF = true
      this.standingsLeagueId = this.tagLeagues[this.tagLeagueIndex].league_id
      Promise.resolve(GoalServeAPI.getLeagueFixtures(this.standingsLeagueId))
        .then(result => {
          if (result.toString() === 'Error: Request failed with status code 500') {
            this.loadingF = false
            return
          }
          console.log(result)
          let arrCheck = Object.keys(result.data.results.tournament)
          if (arrCheck.filter(item => item === 'stage').length > 0) {
            if (Array.isArray(result.data.results.tournament.stage)) {
            } else {
              this.leagueFixtures = result.data.results.tournament.stage.week
            }
          } else {
            this.leagueFixtures = result.data.results.tournament.week
          }
          for (var i in this.leagueFixtures) {
            let recentMatch = false
            for (var j in this.leagueFixtures[i].match) {
              let match = this.leagueFixtures[i].match[j]
              let d = match['@date'].split('.')
              let matchDate = new Date(d[2], d[1] - 1, d[0]).getTime()
              let nowStart = new Date().getTime() - 1000 * 3600 * 24 * 3 // Minus 3 days
              let nowEnd = new Date().getTime() + 1000 * 3600 * 24 * 3 // Plus 3 days
              if (matchDate > nowStart && matchDate < nowEnd) {
                //
                this.leagueFixtures[i]['@recent'] = true
                this.leagueFixtures[i].match[j]['@recent'] = true
                recentMatch = true
              } else {
                if (!recentMatch) this.leagueFixtures[i]['@recent'] = false
                this.leagueFixtures[i].match[j]['@recent'] = false
              }
            }
          }
          this.loadingF = false
        }).catch(error => {
          this.loadingF = false
          console.log(error)
        })
    },
    addRemoveToMyMatchesFixtures(matchitem) {
      if (this.getMyMatches.filter(item => item['@fix_id'] === matchitem['@fix_id']).length === 1) {
        let index = this.getMyMatches.findIndex(item => item['@fix_id'] === matchitem['@fix_id'])
        this.$store.commit('RemoveMyMatch', index)
        this.snackbarText = this.lang[this.getLanguage].MATCH_REMOVED_FROM_FAV
        this.snackbar = true
      } else {
        matchitem['@fix_id'] = matchitem['@id']
        matchitem['@formatted_date'] = matchitem['@date']
        matchitem.file_group = null
        matchitem.ht = matchitem.halftime
        // let date = matchitem['@date'].split('.')[2] + '-' + matchitem['@date'].split('.')[1] + '-' + matchitem['@date'].split('.')[0]
        // matchitem['@date'] = dateformat(new Date(date), 'mmm dd')
        matchitem.events = null
        this.$store.commit('AddMyMatch', matchitem)
        this.snackbarText = this.lang[this.getLanguage].MATCH_ADDED_TO_FAV
        this.snackbar = true
      }
    },
    makeDate(formattedDate) {
      let d = formattedDate.split('.')
      let date = new Date(d[2], d[1] - 1, d[0])
      return date ? date.toLocaleString("id-ID", {dateStyle: 'full'}) : ''
    },
    handleSuccess(e) {
    },
    handleError(e) {
    },
    replaceApos(string) {
      return string.replace('&apos;', '\'')
    },
    inMyMatches(matchitem) {
      return this.getMyMatches.filter(item => item['@fix_id'] === matchitem['@fix_id']).length === 1
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous
      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed / msPerYear) + ' years ago'
      }
    },
    timeZoneCalc(time, formattedDate) {
      let d = formattedDate.split('.')
      let t = time.split(':')
      let date = new Date(d[2], d[1] - 1, d[0], t[0], t[1])
      let tz = date.getTimezoneOffset() / 60 * -1

      let compareDate1 = dateformat(new Date(date).getTime(), 'yyyy-mm-dd')
      let compareDate2 = dateformat(new Date(date).getTime() + 1000 * 60 * 60 * tz, 'yyyy-mm-dd')
      let plusDay = ''
      if (compareDate1 !== compareDate2) {
        plusDay = '+1 Day'
      }
      return dateformat(new Date(date).getTime() + 1000 * 60 * 60 * tz, 'HH:MM') + '##' + plusDay
    }
  }
}
