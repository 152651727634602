
export default {
  name: "backbutton",
  props: {
    className: String,
  },
  data() {
    return {};
  },
  components: {},
  watch: {},
  computed: {},
  beforeDestroy() {},
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
