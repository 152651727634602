'use strict'
import axios from 'axios'

const GS_API = 'https://www.goalserve.com/getfeed/'
const TOKEN = '722a5b800aaa4fed178b08d7fbc25016'


let getters = null
class GoalServeAPI {
  constructor () {
    getters = getters || this
    return getters
  }

  // ####################################################################
  // ######################### Trending Indonesia #######################
  // ####################################################################

  async getLeagues () {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerfixtures/data/mapping?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getLivescoresToday (type) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccernew/' + type + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getLeagueStandings (leagueid) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/standings/' + leagueid + '.xml?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getLeagueFixtures (leagueid) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerfixtures/leagueid/' + leagueid + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getLeagueTeams (leagueid) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerleague/' + leagueid + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getCoachDetail (coachid) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerstats/coach/' + coachid + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getPlayerDetail (playerid) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerstats/player/' + playerid + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getMatchDetails (leagueId, matchId, staticId, date, today) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    // commentaries/match?id=2630581&league=1229&json=1
    let getURL
    if (today === 'yes') {
      getURL = GS_API + TOKEN +  '/commentaries/' + leagueId + '.xml?json=1'
    } else {
      getURL = GS_API + TOKEN +  '/commentaries/match?id=' + staticId + '&league=' + leagueId + '&json=1'
    }
    return await axios.get(getURL, config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  // H2H
  // https://www.goalserve.com/getfeed/722a5b800aaa4fed178b08d7fbc25016/h2h/10677/10347?json=1
  async getH2HDetails (localTeamId, visitorTeamId) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    // commentaries/match?id=2630581&league=1229&json=1
    let getURL = GS_API + TOKEN +  '/h2h/' + localTeamId + '/' + visitorTeamId + '?json=1'
    return await axios.get(getURL, config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  // Commentariers
  // https://www.goalserve.com/getfeed/722a5b800aaa4fed178b08d7fbc25016/commentaries/germany?date=03.06.2020&json=1
  //

  //https://www.goalserve.com/getfeed/722a5b800aaa4fed178b08d7fbc25016/soccerhighlights/d-1?json=1
  async getHighlightsToday (type) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerhighlights/' + type + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

  async getSoccerStats (teamId) {
    const config = {
      headers: {
        'Content-Type': 'application/json'
        // 'X-Authentication': token
      }
    }
    return await axios.get(GS_API + TOKEN +  '/soccerstats/team/' + teamId + '?json=1', config)
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  }

}
export default new GoalServeAPI()
